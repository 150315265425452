import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import tw, { styled } from "twin.macro";

const Layout = styled.div`
  ${tw`min-h-screen container lg:mx-auto flex items-center justify-center`};
`;

const Content = styled.div`
  ${tw`p-8 w-1/3 my-auto text-center bg-gray-700 rounded-lg shadow-2xl`}
`;

const H1 = styled.h1`
  ${tw`pb-4 text-3xl font-black text-white`}
`;

const P = styled.p`
  ${tw`pb-12 text-base text-white`}
`;

const ColorizedLink = styled(Link)`
  ${tw`text-base font-bold text-gray-300 hover:text-white`}
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Content>
      <H1>NOT FOUND</H1>
      <P>You just hit a route that doesn&#39;t exist... the sadness.</P>
      <ColorizedLink to="/"> &lsaquo; Go back to homepage</ColorizedLink>
    </Content>
  </Layout>
);

export default NotFoundPage;
